<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <div class="d-flex justify-content-center oys-logo">
            <b-img class="oys-logo" :src="oysLogo"/>
          </div>
        </b-link>
        <h2 class="mb-1 text-center text-1">Hoşgeldiniz</h2>
        <p class="text-center">
          Sayın <strong>{{ fullName }}, </strong>oturumunuzun süresi dolmuştur.
        </p>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- <b-form-group label="Firma Kodu" label-for="basicInput">
              <b-form-input v-model="firmaKodu" id="basicInput" />
            </b-form-group>
            <b-form-group label="Kullanıcı Adı" label-for="basicInput">
              <b-form-input v-model="userName" id="basicInput" />
            </b-form-group> -->
            <b-form-group label="Şifreniz" label-for="reset-password-new">
              <b-input-group class="input-group-merge">
                <b-form-input
                  ref="focusMe"
                  id="reset-password-new"
                  v-model="password"
                  :type="password1FieldType"
                  class="form-control-merge"
                  name="reset-password-new"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="password1ToggleIcon"
                    @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-button block type="submit" variant="info">
              Oturum Aç
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2 another-account">
          <b-link :to="{ name: 'login' }"> Farklı Hesap İle Oturum Aç</b-link>
        </p>
      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BImg,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BImg,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      oysLogo: require('@/assets/images/oys/logo/logo.png'),
      password1FieldType: 'password',
      password2FieldType: 'password',
      password: '',
      firmaKodu: '',
      userName: '',
      fullName: '',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$store
        .dispatch('logIn', {
          // firmaKodu: this.firmaKodu,
          user: this.userName,
          pwd: this.password,
        })
        .then((res, position = 'bottom-right') => {
          this.$router.push('/')
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Sisteme Hoşgeldiniz',
                text: 'Öğretmenevi Yönetim Sistemi',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            },
            { position }
          )
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Hata!',
              text: err.responseJSON.errMsg,
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
  created() {
    this.firmaKodu = localStorage.getItem('raporFirmaKodu')
    this.userName = localStorage.getItem('raporUserName')
    this.fullName = localStorage.getItem('raporFullName')
    this.$toast({
      component: ToastificationContent,
      props: {
        title: 'Oturum süresi dolmuştur',
        text: 'Tekrar giriş yapmalısınız',
        icon: 'AlertCircleIcon',
        variant: 'bgDanger',
      },
    })
  },
  mounted() {
    this.$refs.focusMe.focus()
  },
}
</script>
<style scoped>
.card {
    border: none;
    margin-bottom: 2rem;
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 20%);
}

.card-body {
    padding: 2.5rem !important;
}

img {
    width: 36% !important;
    cursor: default !important;
}

.auth-wrapper {
    background-image: url("../../assets/images/oys/login/macos-dark.jpg") !important;
    background-size: 100% 120% !important;
}

.another-account {
    text-decoration: underline !important;
    font-size: 16px;
}

.oys-logo {
    margin-bottom: 1rem !important;
}

.brand-logo {
    margin: 0 !important;
    margin-top: 5px !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
